import './App.css';
import React from 'react';
import { Route, Switch,} from 'react-router-dom'




import HomePage from './pages/HomePage'
import Contact from './pages/Contact'
import Sent from './pages/Sent'


function App() {
  return (

              <Switch>
                  <Route exact path='/' component={HomePage} />
                  <Route path='/Sent' component={Sent} />
                  <Route path='/Contact' component={Contact} />
              </Switch>



  );

}

export default App;
