
import React from 'react'
import logo from '../xxivk_lg_bk3.svg';

//import {VFXImg} from 'react-vfx';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import AppsIcon from '@mui/icons-material/AppsOutlined';
import EmailIcon from '@mui/icons-material/MailOutlineOutlined';
import GrainOutlinedIcon from '@mui/icons-material/BlurOnOutlined';



function HomePage() {

    return (
       
            <div className="App">
                <header className="App-header">  

                    <img className="App-logo" src={logo} alt="logo"/>
                    {/*<Button style={{ color: "black" }}  href={"/Contact"}>
                        <EmailIcon />
    </Button>*/}
    <Stack spacing={2} direction="row">
    <Button disabled style={{ color: "black" }} href={""}>XXIVk</Button>
        <Button disabled style={{ color: "black" }} href={""}>Portfolio</Button> 
        <Button style={{ color: "black" }} href={"/Contact"}>Contact</Button>
    </Stack>
    
    



                   
                </header>
            </div>
   
            

)
}


export default HomePage

