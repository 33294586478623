//Import
import React from 'react';
import { Form, Field } from 'react-final-form';
import {TextField, Select } from 'final-form-material-ui';
import {Paper, Grid, Button, MenuItem,} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios'
import { useHistory } from "react-router-dom";


//Slack
//const url = "https://hooks.slack.com/services/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" //TEST
const url = "https://hooks.slack.com/services/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" //LIVE

//Styling
const useStyles = makeStyles((theme) => ({
    paperPadding: {
        padding: '30px',
    },
}));


const onSubmit = async (values) => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);

    //slack
    var websiteform = {
        "channel": "C1H9RESGL",
        "blocks": [
            {
                "type": "section",
                "text": {
                    "type": "mrkdwn",
                    "text": "*Type of Project | Type de Projet:*"+
                        "\n"+ values.project +
                        "\n*Budget:*"+"" +
                        "\n"+values.budget+
                        "\n*Description:*"+
                        "\n"+values.notes
                },
                "accessory": {
                    "type": "image",
                    "image_url": "https://api.slack.com/img/blocks/bkb_template_images/approvalsNewDevice.png",
                    "alt_text": "computer thumbnail"
                }
            },
            {
                "type": "section",
                "text": {
                    "type": "mrkdwn",
                    "text": "Client Info | Info Client:"+
                        "\n"+values.Name + " | " + values.email
                }
            }
        ]
    }


    const data = websiteform
    await axios.post(url, JSON.stringify(data), {
        withCredentials: false,
        transformRequest: [(data, headers) => {
            delete headers.post["Content-Type"]
            return data
        }]
    })
    window.location = '/Sent';






};
const validate = (values) => {
    const errors = {};
    if (!values.Name) {
        errors.Name = 'Required | Obligatoire';
    }
    if (!values.email) {
        errors.email = 'Required | Obligatoire';
    }
    if (!values.project) {
        errors.project = 'Required | Obligatoire';
    }
    if (!values.budget) {
        errors.budget = 'Required | Obligatoire';
    }
    return errors;
};


export default function Contact() {
    const classes = useStyles();

    return (
            <div className="App">
                <header className="Contact-header">

        <Form
            onSubmit={onSubmit}
            //initialValues={{ stooge: 'larry' }}
            validate={validate}
            render={({ handleSubmit, reset, submitting, pristine, values }) => (
                <form  className='form' onSubmit={handleSubmit} noValidate>
                    <Paper className={classes.paperPadding}>
                        <Grid container alignItems="flex-start" spacing={2}>
                            <Grid item xs={12}>
                                <Field
                                    fullWidth
                                    required
                                    name="Name"
                                    component={TextField}
                                    type="text"
                                    label="Name | Nom"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name="email"
                                    fullWidth
                                    required
                                    component={TextField}
                                    type="email"
                                    label="Email"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    fullWidth
                                    name="project"
                                    required
                                    component={Select}
                                    label="Type of project | Type de projet"
                                    formControlProps={{ fullWidth: true }}
                                >
                                    <MenuItem value="Branding">Branding</MenuItem>
                                    <MenuItem value="Coding">Coding</MenuItem>
                                    <MenuItem value="Film, Animation">Film, Animation</MenuItem>
                                    <MenuItem value="UI/UX design">UI/UX design</MenuItem>
                                    <MenuItem value="Web Design">Web Design</MenuItem>

                                </Field>
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    fullWidth
                                    required
                                    name="budget"
                                    component={Select}
                                    label="Budget"
                                    formControlProps={{ fullWidth: true }}
                                >
                                    <MenuItem value="$10k - $25k">$10k - $25k</MenuItem>
                                    <MenuItem value="$25k - $50k">$25k - $50k</MenuItem>
                                    <MenuItem value="$50k- $100k">$50k - $100k</MenuItem>
                                    <MenuItem value=">$100k - $500k">$100k - $500k</MenuItem>
                                    <MenuItem value=">$500k - $1m">$500k - $1m</MenuItem>
                                </Field>
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    fullWidth
                                    name="notes"
                                    component={TextField}
                                    multiline
                                    label="Description"
                                />
                            </Grid>

                            <Grid item style={{ marginTop: 16 }}>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    onClick={reset}
                                    disabled={submitting || pristine}
                                >
                                    Reset
                                </Button>
                            </Grid>
                            <Grid item style={{ marginTop: 16 }}>
                                <Button
                                    variant="outlined"
                                    color="default"
                                    type="submit"
                                    disabled={submitting}
                                >
                                    Submit | Envoyer
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>

                </form>
            )}
        />




<br></br>

<Button style={{ color: "black" }} href={"/"}>
    <HomeIcon />
</Button>



</header>
</div>

);
}

