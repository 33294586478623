//Import
import React from 'react';
import {VFXSpan} from 'react-vfx';
import {Button} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';



export default function Sent() {

    return (
        <div className="App">
            <header className="Contact-header">

                <VFXSpan shader="glitch">Sent | Envoyé</VFXSpan>
                <br></br>
                <Button variant="outlined"  color="secondary" href={"/"}>
                    <HomeIcon />
                </Button>



            </header>
        </div>

    );
}













